<template>
    <v-container fluid>
        <v-row fill-height>
            <v-col cols="3" fill-height>
                <v-card>
                    <v-card-title class="cardtitle mb-2 rounded-0 elevation-0">Configuration</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="name"
                            label="Filename"
                        />
                        <v-text-field
                            v-model="description"
                            label="Description"
                        />
                        <h3>Filters</h3>
                        <v-select
                            v-model="country"
                            :items="countries"
                            label="Country"
                            v-on:change="selcountry"
                            background-color="white"
                            filled
                            dense
                            v-if="superadmin"
                        />
                        <v-select
                            v-model="region"
                            :items="regions"
                            label="Region"
                            v-on:change="selregion"
                            background-color="white"
                            filled
                            dense
                        />
                        <v-select
                            v-model="areaprotectionstatus"
                            :items="area_classification"
                            label="Area Protection Status"
                            v-on:change="selareaprotectionstatus"
                            background-color="white"
                            filled
                            dense
                        />
                        <v-select 
                            v-model="baitfound"
                            :items="bait" 
                            label="Poison Bait Found"
                            v-on:change="selbait"
                            background-color="white"
                            filled
                            dense
                        />
                        <v-select
                            v-model="dogunit"
                            :items="antipoison_unit"
                            label="Antipoison Dog Unit"
                            v-on:change="seldogunit"
                            background-color="white"
                            filled
                            dense
                        />
                        <v-select
                            v-model="complaint"
                            :items="official_complaint" 
                            label="Official Complaint" 
                            v-on:change="selcomplaint"
                            background-color="white"
                            filled
                            dense
                        />
                        <v-select 
                            v-model="takentocourt" 
                            :items="court_action" 
                            label="Case Taken To Court"
                            v-on:change="seltakentocourt"
                            background-color="white"
                            filled
                            dense
                        />
                        <v-select
                            v-model="typeofanimal"
                            :items="animal_species"
                            label="Type of Animal"
                            v-on:change="seltypeofanimal"
                            background-color="white"
                            filled
                            dense
                        />

                        <!-- Date range -->
                        <v-row>
                            <v-col>
                                <v-menu
                                    v-model="datefromMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="datefromdisplay"
                                        value="kalikantzaros"
                                        label="Date From"
                                        persistent-hint
                                        readonly
                                        v-on="on"
                                        append-icon="mdi-calendar"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="datefrom"
                                    :locale="locale"
                                    min="1980-01-01"
                                    max="2050-01-01"
                                    @change="setdatefrom"
                                    @input="datefromMenu = false"
                                ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col>
                                <v-menu
                                    v-model="datetoMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="datetodisplay"
                                        label="Date To"
                                        persistent-hint
                                        readonly
                                        v-on="on"
                                        :disabled="needDateFrom"
                                        append-icon="mdi-calendar"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="dateto"
                                    :locale="locale"
                                    min="1980-01-01"
                                    max="2050-01-01"
                                    @change="setdateto"
                                    @input="datetoMenu = false"
                                ></v-date-picker>
                                </v-menu>
                            </v-col>
                    </v-row>

                    </v-card-text>

                    <v-card-actions>
                        
                        <v-btn  color="primary" @click.stop="resetFilters" :disabled="!filtered">
                            Clear Filters
                        </v-btn>
                        <v-spacer />
                        <v-btn color="green" @click.stop="saveExport" :disabled="!canSave">
                            <span v-if="!exportLoading">Save</span>
                            <v-progress-circular indeterminate v-else />
                        </v-btn>
                    
                        <v-btn color="red" @click.stop="cancel">
                            Cancel
                        </v-btn>
                            
                    </v-card-actions>
                </v-card>
            </v-col>


            <v-col cols="9">
                <v-card>
                   <v-data-table
                    :headers="headers"
                    :items="indexes"
                    :loading="exportLoading"
                    class="elevation-1"
                   >
                     <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Preview Filtered Incidents - <small>max.20 incidents sampled</small></v-toolbar-title>
                        </v-toolbar>
                     </template>
                   </v-data-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>


<script>
import { get, call } from "vuex-pathify";
import { AnimalSpecies } from "@/utils/selectoptions.js"

const regionInfo = require('@/assets/countries_regions.json')

export default {
  data: function () {
    return {
        loading: false,
        firstSave: true,    // tracks if we are in new Export
        // Filter panel
        filtered: false,    // tracks the state of filtering
        filters: {
            country: "",
            region: "",
            poisonBaitFound: "",
            antipoisonDogUnitUsed: "",
            officialComplaint: "",
            caseTakenToCourt: "",
            areaProtectionStatus: "",
            TypeOfAnimal: "",
            speciesfilter: "",
            dateFrom: "",
            dateTo: "",
        },
        // Filter forms
        name: "",
        description: "",
        country: "",
        region: "",
        baitfound: "",
        dogunit: "",
        complaint: "",
        takentocourt: "",
        areaprotectionstatus: "",
        typeofanimal: "",
        animalspecies: "",
        datefrom: "",
        datefromdisplay: "",
        dateto: "",
        datetodisplay: "",
        datefromMenu: false,
        datetoMenu: false,
        totalResults: 0,
        needDateFrom: true,
        datefilter: false,

        // Filter data
        countries: [
            "Albania",
            "Bosnia and Herzegovina",
            "Bulgaria",
            "Croatia",
            "Greece",
            "North Macedonia", 
            "Serbia"
        ],
        area_classification: [
            "National Park",
            "Nature park",
            "Natural reserve",
            "Controlled hunting area",
            "Wildlife sanctuary",
            "SPA",
            "SCI",
            "SPA/SCI",
            "Ramsar",
            "IBA",
            "Biosphere reserve",
            "None",
            "Unknown",
        ],
        animal_type: [
            "Bird",
            "Cat",
            "Dog",
            "Farm animal",
            "Impossible to identify",
            "Reptile",
            "Insect",
            "Wild mammal",
        ],
        bait: ["Yes", "No", "Unknown"],
        antipoison_unit: ["Yes", "No", "Unknown"],
        official_complaint: ["Yes", "No", "Unknown"],
        court_action: ["Yes", "No", "Unknown", "Not Applicable"],
        animal_species: AnimalSpecies,
    };
  },
  methods: {
      dosave: call('exportserv/createExport'),
      doupdate: call('exportserv/updateExport'),
      preview: call('exportserv/exportPreview'),
      dynpreview: call('exportserv/getPreview'),
      resetFilters () {
        this.loading = true
        // Reset filters array
        this.filters = {
            country: "",
            region: "",
            poisonBaitFound: "",
            antipoisonDogUnitUsed: "",
            officialComplaint: "",
            caseTakenToCourt: "",
            areaProtectionStatus: "",
            TypeOfAnimal: "",
            speciesfilter: "",
            dateFrom: "",
            dateTo: "",
        };

        // Reset the select models
        this.country = "";
        this.region = "";
        this.baitfound = "";
        this.dogunit = "";
        this.complaint = "";
        this.takentocourt = "";
        this.areaprotectionstatus = "";
        this.typeofanimal = "";
        this.dateto = "";
        this.datefrom = "";
        this.animalspecies = "";

        // Reset the date fields
        this.datefromdisplay = "";
        this.datetodisplay = "";

        // Reset the filtered state flag
        this.filtered = false;
        this.needDateFrom = true;
        this.datefilter = false

        // Reset the total Results
        this.loading = false
      },
      selcountry(evt) {
        this.loading = true
        this.filtered = true
        this.filters.country = evt
        this.preview()
      },
      selregion(evt) {
        this.loading = true
        this.filtered = true
        this.filters.region = evt
        this.preview()
      },
      selbait(evt) {
        this.loading = true
        this.filtered = true
        this.filters.poisonBaitFound = evt
        this.preview()
      },
      seldogunit(evt) {
        this.loading = true
        this.filtered = true
        this.filters.antipoisonDogUnitUsed = evt
        this.preview()
      },
      selcomplaint(evt) {
        this.loading = true
        this.filtered = true
        this.filters.officialComplaint = evt
        this.preview()
      },
      seltakentocourt(evt) {
        this.loading = true
        this.filtered = true
        this.filters.caseTakenToCourt = evt
        this.preview()
      },
      selareaprotectionstatus(evt) {
        this.loading = true
        this.filtered = true
        this.filters.areaProtectionStatus = evt
        this.preview()
      },
      seltypeofanimal(evt) {
        this.loading = true
        this.filtered = true
        this.filters.TypeOfAnimal = evt
        this.preview()
      },
      seltypeofspecies(evt) {
        this.loading = true
        this.filtered = true
        this.filters.speciesfilter = evt
        this.preview()
      },
      setdatefrom(evt) {

        // Apply date formatting as required
        this.datefromdisplay = this.formatDate(evt)

        // enable the form for the From
        this.needDateFrom = false

        this.filters.dateFrom = evt

        if (this.dateTo !== "") this.preview()
      },
      setdateto(evt) {
        // Apply date formatting as required
        this.datetodisplay = this.formatDate(evt)

        this.filters.dateTo = evt
        this.preview()
      },
      formatDate (date) {
        if(!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      sampleExport () {
      },
      cancel () {
        this.$router.back()
      },
      async saveExport () {
        let payload = {
            "name": this.name,
            "description": this.description,
            "data": JSON.stringify(this.filters)
        }

        // if this is new save, redirect to new page
        if (this.firstSave === true) {
            await this.dosave(payload)
            this.$router.push({name: "View Export", params: {id: this.export.id}})
        } else {
            // console.log('saveExport - payload:' + payload)
            await this.doupdate({id: this.export.id, payload: payload})
            // await this.preview(this.export.id)
            this.$router.push({name: "Exports"})
        }

        
      },
      preview () {
        let payload = {
            data: JSON.stringify(this.filters)
        }
        this.dynpreview(payload)
      }
  },
  computed: {
    superadmin: get('newauth@userProfile.is_superuser'),
    usercountry: get('newauth@userProfile.country'),
    expIndexes: get('exportserv/getIndexes'),
    export: get('exportserv/getExport'),
    exportLoading: get('exportserv/exportLoading'),
    locale() {
        return this.$i18n.locale
    },
    canSave() {
        return this.name !== ""? true : false
    },
    headers () {
        return [
            {
                text: "EntryCode",
                value: "entrycode"
            },
            {
                text: "Notification Date",
                value: "dateOfEvent"
            },
            {
                text: "Country",
                value: "country"
            },
            {
                text: "Region",
                value: "region"
            },
            {
                text: "Municipality",
                value: "municipality"
            },
            {
                text: "Species found dead",
                value: "TypeOfAnimal"
            },
            {
                text: "Poison bait found",
                value: "poisonBaitFound"
            },
            {
                text: "Autopsy and/or tox. analyses carried out",
                value: "autopsyAndMore"
            },
            {
                text: "Antipoison Dog Unit used",
                value: "antipoisonDogUnitUsed"
            },
            {
                text: "Live",
                value: "live"
            }
        ]
    },
    indexes () {
        // BUG here on the first save, the dynPreview is not working
        // correctly...
        if (this.firstSave === true) {
            if (!this.expIndexes) return []
            else return this.expIndexes
        }  //return []
        else return this.expIndexes
    },
    // regions () {
    //     let regions = []
    //     if (this.country) {
    //         regions = Object.keys(regionInfo[this.country])
    //     } else {
    //         regions = ['n/a']
    //     }
    //     return regions
    // }
    regions () {
        let regions = []
        if (!this.superadmin) {
            regions = Object.keys(regionInfo[this.usercountry])
            this.country = this.usercountry
        } else if (this.country) {
            console.log('we are in the other condition')
            console.log('and the contry is: ' + this.country)
            regions = Object.keys(regionInfo[this.country])
        } else {
            regions = ['n/a']
        }
        return regions
    }
  },
  mounted () {
    let _id = this.$route.params.id? this.$route.params.id : false
    // console.log('mounted - the _id is:' + _id)
    this.firstSave = (_id === false) ? true : false

    if (this.firstSave === false) {
        this.name = this.export.name
        this.description = this.export.description
        this.filters = this.export.data
        this.country = this.filters.country
        this.region = this.filters.region
        this.baitfound = this.filters.poisonBaitFound
        this.dogunit = this.filters.antipoisonDogUnitUsed
        this.complaint = this.filters.officialComplaint
        this.takentocourt = this.filters.caseTakenToCourt
        this.areaprotectionstatus = this.filters.areaProtectionStatus
        this.typeofanimal = this.filters.TypeOfAnimal
        this.animalspecies = this.filters.speciesfilter

        // Dates
        this.datefromdisplay = this.formatDate(this.filters.dateFrom)
        this.datetodisplay = this.formatDate(this.filters.dateTo)
        if (this.datefromdisplay !== "" && this.datetodispla !== "") this.needDateFrom = false
    }
  },
  created () {
    let _id = this.$route.params.id
    // console.log('created - the _id is:' + _id)
  }
};
</script>


<style scoped>
.cardtitle {
   background-color: #7eb09f;   
}
</style>